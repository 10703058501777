import { SVGIcon } from '@data/svg-icons.data';

export interface IValueText<T = string | number | boolean | Date> {
  value?: T | null;
  text?: string | null;
  icon?: SVGIcon | null;
}
export interface IValueLabel<T = string | number | boolean | Date> {
  value?: T | null;
  label?: string | null;
  icon?: SVGIcon | null;
}

export enum UserOptionsEnum {
  changePassword = 'changePassword',
  // @Note - QA-1996
  // accounts = 'accounts',
  logOut = 'logOut'
}
export type UnsafeObject<T extends object> = object & Partial<T>;
export type UnsafeValue<T extends string | number | boolean> = object & T;
